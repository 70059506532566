input:not([type=submit]), textarea, select {
	appearance: none;
	display: block;
	width: 100%;
	padding: 8px 15px;
	border: none;
	font: inherit;
	background: #f0efef;
	margin-bottom: 2rem;
	border-radius: 0;
}

label {
	@include adelle-sans(700);

	font-style: italic;
	margin-bottom: 5px;
	display: inline-block;
	text-transform: lowercase;
}

input[type=submit] {
	@extend %link;

	appearance: none;
	border: none;
	color: $blue;
	border-bottom: 3px solid $green;
	padding-top: 25px;
	background-color: transparent;
	cursor: pointer;
	border-radius: 0;
}

#submit {
	display: inline-block;
	position: relative;
	cursor: pointer;

	& label {
		cursor: pointer;
		width: 100%;
		height: 30px;
		background-image: image_url('layout/submit.svg');
		background-color: transparent;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		position: absolute;
		top: 0;
		transform-origin: center center;
	}

	&:hover label {
		animation-name: rotate;
		animation-duration: 0.5s;
		animation-count: 1;
	}
}

select {
	text-transform: uppercase;
	background-image: image_url('layout/arrow-right.svg');
	background-repeat: no-repeat;
	background-position: 90% 50%;
	background-size: 15px;
	line-height: 1rem;
	height: 55px;

	&:focus {

	}
}

.sub {
	@include adelle-sans(300);

	display: inline-block;
	font-style: italic;
	font-size: 0.75rem;
	margin-left: 10px;
}

@keyframes rotate {
	100% {
		transform: rotate(-360deg);
	}
}
