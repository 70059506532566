.positioning-statement {
	text-align: center;
	padding: 2.5rem 1rem 4rem 1rem;
	margin: 0px auto;
	max-width: 50rem;

	& h1 {
		font-size: 2.5rem;
		text-transform: uppercase;
		line-height: 125%;
		margin-top: 0;
	}

	& h2 {
		font-size: 1.5rem;
		text-transform: uppercase;
		margin-top: 0;
	}

	& p {
		@include proxima-nova;

		display: block;
		padding: 0.5rem 0 0 0;

		&.large {
			font-size: 1.15rem;
			line-height: 175%;
		}
	}

	& img {
		display: block;
		max-width: 38px;
		margin: 0.5rem auto;
		max-height: 80px;
	}
}