.footer {
	background-image: image-url('layout/hives.jpg');
	text-align: center;

	@include breakpoint(small only) {
		margin-bottom: -16px;
	}

	& #copyright {
		@include lato(300);

		text-transform: lowercase;
		color: $gray;
		padding: 2.5rem 0;
		display: block;
		font-size: 0.75rem;

		& a {
			text-decoration: none;
			color: inherit;
		}
	}

	& .separator {
		padding-left: 0.75rem;
		padding-right: 0.75rem;
	}

	& .social-icons {
		list-style: outside none;
		padding-left: 0;
		display: flex;
		flex-direction: row;
		justify-content: center;
		position: relative;
		top: 10px;

		@include breakpoint(medium only) {
			margin-bottom: 0;
		}

		& .twitter {
			position: relative;
			top: 5px;
		}

		> li {
			flex: 0 0 auto;
			align-self: flex-end;
			padding: 0 1rem;
			height: 80px;
			transition: top 0.3s;
			position: relative;
			top: 0;

			&:hover {
				top: -8px;
			}
		}
		img {
			max-height: 100%;
			width: 52px;
		}

	}
}

.sub-footer {
	@include clearfix;

	background: $gray;
	color: #fff;
	line-height: 1.5rem;

	@include breakpoint(large) {
		margin-top: -15px;
	}

	& .quote {
		@include lato(300);

		padding: 1rem 1.5rem;
		float: left;

		& .em {
			@include adelle-condensed(700);

			font-style: italic;
			padding-right: 10px;
			letter-spacing: 0.05rem;
		}

		& a {
			color: inherit;
			text-decoration: inherit;
		}
	}

	& .contact {
		@include adelle-condensed(700);

		font-style: italic;
		background: $red;
		letter-spacing: 0.05rem;
		padding: 1rem 5rem 1rem 1.5rem;
		color: #fff;
		text-decoration: none;
		position: relative;
		overflow: hidden;

		@include breakpoint(small) {
			display: inline-block;
			width: 100%;
		}
		@include breakpoint(large) {
			float: right;
			display: initial;
			width: auto;
			font-size: 1rem;
		}

		& .alfie {
			position: absolute;
			width: 35px;
			height: 35px;
			bottom: 0;

			@include breakpoint(small) {
				right: 40px;
			}
			@include breakpoint(large) {
				right: 35px;
			}
		}

		& .hand {
			position: absolute;
			bottom: 0;
			transform-origin: 50% 100%;
			width: 16px;
			height: 16px;

			@include breakpoint(small) {
				right: 23px;
			}
			@include breakpoint(large) {
				right: 18px;
			}
		}

		&:hover .hand {
			animation-name: hand-wave;
			animation-iteration-count: infinite;
			animation-direction: alternate;
			animation-duration: 0.25s;
			animation-timing-function: ease-in-out;
		}
	}
}

@keyframes hand-wave {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(15deg);
	}
}
