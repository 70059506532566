/* Off-Canvas Config */
$offcanvas-background: $blue;
$offcanvas-size: 100%;

@include foundation-off-canvas;

/*
 * All the header functions in this class are split on the .at-top class.  When
 * the user is scrolled to the top of the page, the at-top class is added.  It
 * is then removed when the user scrolls off the top.
 *
 * The Body and Header object will get the .at-top class.
 */

/* Transition time between at-top and reduced height */
$top-change-time: 0.25s;

/* The padding around the top and bottom of the logo */
$logo-padding: 7px;

/* The "At-Top" or full height of the nav bar */
$full-height: (
	large: 120px,
	medium: 100px,
	small: 85px
);

/* The Reduced height height of the nav bar */
$reduced-height: (
	large: 55px,
	medium: 50px,
	small: 50px
);

/* When we have an alert to display, we push all the content down by this amount */
$alert-height: 53px;

/* Size of the mobile navigation toggle */
$mobile-toggle-height: 30px;

/* We override this to make the off-canvas nav tall */
.off-canvas.position-left {
	min-height: 100%;
	
	@include breakpoint(small only) {
		margin-top: map-get($reduced-height, small);
	}
}

/* Adjust the body's top padding to account for the current nav bar height. */
body {
	transition: padding $top-change-time;

	@include breakpoint(small) {
		padding: map-get($reduced-height, small) + 5px 0 0 0;
	}
	@include breakpoint(medium) {
		padding: map-get($reduced-height, medium) + 5px 0 0 0;
	}
	@include breakpoint(large) {
		padding: map-get($reduced-height, large) + 5px 0 0 0;
	}

	&.at-top {
		@include breakpoint(small) {
			padding: map-get($full-height, small) 0 0 0;
		}
		@include breakpoint(medium) {
			padding: map-get($full-height, medium) 0 0 0;
		}
		@include breakpoint(large) {
			padding: map-get($full-height, large) 0 0 0;
		}
	}

	&.with-alert {
		@include breakpoint(small) {
			padding: map-get($reduced-height, small) + 5px 0 0 0;
		}
		@include breakpoint(medium) {
			padding: map-get($reduced-height, medium) + 5px + $alert-height 0 0 0;
		}
		@include breakpoint(large) {
			padding: map-get($reduced-height, large) + 5px + $alert-height 0 0 0;
		}

		&.at-top {
			@include breakpoint(small) {
				padding: map-get($full-height, small) 0 0 0;
			}
			@include breakpoint(medium) {
				padding: map-get($full-height, medium) + $alert-height 0 0 0;
			}
			@include breakpoint(large) {
				padding: map-get($full-height, large) + $alert-height 0 0 0;
			}
		}
	}
}

/* Menu link styles */
.menu {
	padding-left: 0;
	transition: $top-change-time;

	@include breakpoint(small only) {
		padding: 1rem;
	}

	& a {
		@include lato(600);

		text-transform: uppercase;
		text-decoration: none;
		font-size: 1rem;
		transition: $top-change-time;

		@include breakpoint(small only) {
			color: #fff;
			padding: 1rem !important;
			font-size: 1.25rem;
			line-height: 1.5rem !important;
		}
		@include breakpoint(medium) {
			padding: 0.6rem 2vw !important;
			color: $black;
		}
		@include breakpoint(large) {
			padding: 0.7rem 2rem !important;
		}

		&:hover, &.active {
			color: $light-gray;
		}
	}

	& li {
		transition: $top-change-time;
	}
}

/*
 * Preload all of our logo and header images to prevent any flicker when we
 * switch to a new logo size.
 */
body:after{
    position:absolute; width:0; height:0; overflow:hidden; z-index:-1;
    content: image-url('layout/hives.jpg') image-url('layout/logo-full.svg') image-url('layout/logo-icon.svg');
}

/* Header styles for both sizes */
.header {
	background-image: image-url('layout/hives.jpg');
	background-size: cover;
	background-position: center center;
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 10;
	transition: border $top-change-time;

	/* We set the color here to keep it from attempting to transition in IE */
	border-bottom-color: $blue;

	& #logo {
		font-size: 0;
		display: block;
		transition: $top-change-time;
		margin: $logo-padding 0;
		position: relative;
		left: 7px;

		/*
		 * We actually apply the background to a pseudo element.  This is a fix
		 * for safari where the background image would become small during the
		 * transition.
		 *
		 * This fixes it without having to use an image for the logo, so it won't
		 * ever be blurry.
		 */
		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: contain;
			background-repeat: no-repeat;
		}
	}

	& .menu {
		float: right;
	}

	& .relative {
		position: relative;
	}

	& .mobile-toggle {
		font-size: 0px;
		position: absolute;
		z-index: 100;
		background-size: cover;
		background-position: 50% 50%;
		border: none;
		height: $mobile-toggle-height;
		width: $mobile-toggle-height;
		cursor: pointer;
		transition: top $top-change-time;
		right: 28px;
	}
}

/* Header objects for reduced size */
.header:not(.at-top) {
	border-bottom: 5px solid $blue;

	& #logo {
		width: 52px;

		@include breakpoint(small) {
			height: map-get($reduced-height, small) - ($logo-padding * 2);
		}
		@include breakpoint(medium) {
			height: map-get($reduced-height, medium) - ($logo-padding * 2);
		}
		@include breakpoint(large) {
			height: map-get($reduced-height, large) - ($logo-padding * 2);
		}

		&:before {
			background-image: image-url('layout/logo-icon.svg');
		}

	}

	& .menu {
		& li {
			@include breakpoint(small) {
				height: map-get($reduced-height, small);
			}
			@include breakpoint(medium) {
				height: map-get($reduced-height, medium);
			}
			@include breakpoint(large) {
				height: map-get($reduced-height, large);
			}
		}

		@include breakpoint(small) {
			height: map-get($reduced-height, small);
		}
		@include breakpoint(medium) {
			height: map-get($reduced-height, medium);
		}
		@include breakpoint(large) {
			height: map-get($reduced-height, large);
		}
	}

	& .mobile-toggle {
		top: (map-get($reduced-height, small) / 2) - ($mobile-toggle-height / 2);
	}
}

/* Header objects for full size */
.header.at-top {
	& #logo {
		width: 75px;

		@include breakpoint(small) {
			height: map-get($full-height, small) - ($logo-padding * 2);
		}
		@include breakpoint(medium) {
			height: map-get($full-height, medium) - ($logo-padding * 2);
		}
		@include breakpoint(large) {
			height: map-get($full-height, large) - ($logo-padding * 2);
		}

		&:before {
			background-image: image-url('layout/logo-full.svg');
			background-position: left center;
		}

	}

	& .menu {
		& li {
			@include breakpoint(small) {
				height: map-get($full-height, small);
			}
			@include breakpoint(medium) {
				height: map-get($full-height, medium);
			}
			@include breakpoint(large) {
				height: map-get($full-height, large);
			}
		}

		@include breakpoint(small) {
			height: map-get($full-height, small);
		}
		@include breakpoint(medium) {
			height: map-get($full-height, medium);
		}
		@include breakpoint(large) {
			height: map-get($full-height, large);
		}
	}

	& .mobile-toggle {
		top: (map-get($full-height, small) / 2) - ($mobile-toggle-height / 2);
	}
}

/* Hamburger menu styles */
.mobile-toggle {
	display: block;
	position: relative;
	overflow: hidden;
	margin: 0;
	padding: 0;
	font-size: 0;
	text-indent: -9999px;
	appearance: none;
	box-shadow: none;
	border-radius: none;
	border: none;
	cursor: pointer;
	transition: background 0.3s;
	overflow: visible;
	background: transparent;
}

.mobile-toggle:focus {
	outline: none;
}

.mobile-toggle span {
	display: block;
	position: absolute;
	height: 2px;
	background: #000;
	width: $mobile-toggle-height - 6px;
	left: 3px;
}

.mobile-toggle span::before,
.mobile-toggle span::after {
	position: absolute;
	display: block;
	left: 0;
	width: $mobile-toggle-height - 6px;
	height: 2px;
	background-color: #000;
	content: "";
}

.mobile-toggle span::before {
	top: -10px;
}

.mobile-toggle span::after {
	bottom: -10px;
}

.mobile-toggle span {
	transition: background 0s 0.3s;
}

.mobile-toggle span::before,
.mobile-toggle span::after {
	transition-duration: 0.3s, 0.3s;
	transition-delay: 0.3s, 0s;
}

.mobile-toggle span::before {
	transition-property: top, transform;
}

.mobile-toggle span::after {
	transition-property: bottom, transform;
}

/* active state, i.e. menu open */

.mobile-toggle.is-active span {
	background: none;
}

.mobile-toggle.is-active span::before {
	top: 0;
	transform: rotate(45deg);
}

.mobile-toggle.is-active span::after {
	bottom: 0;
	transform: rotate(-45deg);
}

.mobile-toggle.is-active span::before,
.mobile-toggle.is-active span::after {
	transition-delay: 0s, 0.3s;
}

#mobile-nav {
	@include breakpoint(medium) {
		display: none;
	}
}

.is-open-left {
	@include breakpoint(medium) {
		transform: none !important;
	}
}

.alert {
	@include adelle-condensed(700);

	font-style: italic;
	background: $red;
	color: $white;
	letter-spacing: 0.75;

	& .columns {
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		position: relative;
		text-align: center;

		@include breakpoint (small only) {
			padding-bottom: 3rem;
			padding-top: 3rem;
		}
	}

	& .alfie {
		position: absolute;
		width: 35px;
		height: 35px;
		bottom: 0;

		@include breakpoint(small) {
			left: 40px;
		}
		@include breakpoint(large) {
			left: 35px;
		}
	}

	& .hand {
		position: absolute;
		bottom: 0;
		transform-origin: 50% 100%;
		width: 16px;
		height: 16px;

		@include breakpoint(small) {
			left: 71px;
		}
		@include breakpoint(large) {
			left: 71px;
		}
	}

	& .close {
		position: absolute;
		top: calc(50% - 8px);
		right: 20px;
		width: 16px;
		height: 16px;
		background: image_url('layout/close.svg');
		background-position: center center;
		background-size: cover;
		cursor: pointer;

		@include breakpoint(small only) {
			top: 20px;
		}
	}
}
