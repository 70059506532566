img {
	&.center {
		margin: 0 atuo;
		display: block;
	}

	&.full {
		width: 100%;
	}
}

hr {
	max-width: $global-width;
	border-top: 1px solid $light-gray;
	border-left: none;
	border-right: none;
	border-bottom: none;
}

h1, h2, h3, h4, h5, h6 {
	@include lato (700);
	letter-spacing: 0.10rem;
	text-transform: uppercase;
}

body {
	@include proxima-nova;
	color: $black;
}

i {
	font-style: italic;
}
