/* Global Colors */
$logo-red: #e51b2d;
$red: #e02726;
$green: #81bd41;
$gray: #565759;
$light-gray: #9fa1a1;
$blue: #0090ba;
$black: #2e2a25;
$yellow: #e9dc2b;

/* Helper function to include images and other assets */
@function image-url($path) {
	@return unquote('url("../img/' + $path + '")');
}

@mixin typekit-js-defer() {
		html:not(.wf-active):not(.wf-inactive) body:not(.no-js) & {
			visibility: hidden !important;
		}
}

/* Font helper mixins */
@mixin lato($weight: 400) {
	@include typekit-js-defer;

	font-family: "lato", "sans-serif";
	font-weight: $weight;
}

@mixin adelle-sans($weight: 400) {
	@include typekit-js-defer;

	font-family: "adelle-sans", "sans-serif";
	font-weight: $weight;
}

@mixin adelle-condensed($weight: 400) {
	@include typekit-js-defer;

	font-family: "adelle-condensed", "serif";
	font-weight: $weight;
}

@mixin proxima-nova($weight: 400) {
	@include typekit-js-defer;

	font-family: "proxima-nova", "sans-serif";
	font-weight: $weight;
}

///
/// Viewport sized typography with minimum and maximum values
///
/// @author Eduardo Boucas (@eduardoboucas)
///
/// @param {Number}   $responsive  - Viewport-based size
/// @param {Number}   $min         - Minimum font size (px)
/// @param {Number}   $max         - Maximum font size (px)
///                                  (optional)
/// @param {Number}   $fallback    - Fallback for viewport-
///                                  based units (optional)
///
/// @example scss - 5vw font size (with 50px fallback),
///                 minumum of 35px and maximum of 150px
///  @include responsive-font(5vw, 35px, 150px, 50px);
///
@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
	$responsive-unitless: $responsive / ($responsive - $responsive + 1);
	$dimension: if(unit($responsive) == 'vh', 'height', 'width');
	$min-breakpoint: $min / $responsive-unitless * 100;

	@media (max-#{$dimension}: #{$min-breakpoint}) {
		font-size: $min;
	}

	@if $max {
		$max-breakpoint: $max / $responsive-unitless * 100;

		@media (min-#{$dimension}: #{$max-breakpoint}) {
			font-size: $max;
		}
	}

	@if $fallback {
		font-size: $fallback;
	}

	font-size: $responsive;
}