/* These maps control the generic banner height and text height. */
$banner-height: (
	'small': 115px,
	'medium': 185px,
	'large': 390px
);

$text-height: (
	'small': 95px,
	'medium': 155px,
	'large': 200px
);

@mixin absolute() {
	position: absolute;

	top: -50%;
	left: 0;
	width: 100%;

	@include breakpoint(small) {
		height: map-get($text-height, small);
	}
	@include breakpoint(medium) {
		height: map-get($text-height, medium);
	}
	@include breakpoint(large) {
		height: map-get($text-height, large);
	}
}

.banner {
	background-repeat: no-repeat;
	position: relative;
	background-size: cover;
	background-position: center center;
	pointer-events: none;

	&.unequal {
		@include breakpoint(small) {
			height: map-get($banner-height, medium) !important;
		}
		@include breakpoint(medium) {
			height: map-get($banner-height, large) !important;
		}
	}

	@include breakpoint(small) {
		height: map-get($banner-height, small) !important;
	}
	@include breakpoint(medium) {
		height: map-get($banner-height, medium) !important;
	}
	@include breakpoint(large) {
		height: map-get($banner-height, large) !important;
	}

	/* Height changes for the home page (it's a bit taller) */
	&#home {
		@include breakpoint(small) {
			height: 350px !important;
		}
		@include breakpoint(medium) {
			height: 450px !important;
		}
		@include breakpoint(large) {
			height: 625px !important;
		}

		& .vertical-center, & .banner-text, & .text-bg {
			@include breakpoint(small) {
				height: map-get($text-height, small);
			}
			@include breakpoint(medium) {
				height: map-get($text-height, medium);
			}
			@include breakpoint(large) {
				height: map-get($text-height, large);
			}
		}
	}

	&.thin {
		@include breakpoint(small) {
			height: map-get($banner-height, small) !important;
		}
		@include breakpoint(medium) {
			height: 150px !important;
		}

		& .banner-text, & .vertical-center {
			@include breakpoint(small) {
				height: map-get($banner-height, small) !important;
			}
			@include breakpoint(medium) {
				height: 150px !important;
			}
		}

		& .title {
			@include breakpoint(small) {
				line-height: map-get($banner-height, small) !important;
			}

			@include breakpoint(medium) {
				line-height: 150px;
			}
		}
	}

	/* Image Declarations for specific pages */
	&#home {
		background-image: image_url('banners/home.jpg');
	}

	&#abilities {
		background-image: image_url('banners/abilities.jpg');
	}

	&#about-creative {
		background-image: image_url('banners/about-creative.jpg');
		background-position: top center;
	}

	&#about-dedicated {
		background-image: image_url('banners/about-dedicated.jpg');
		background-position: top center;
	}

	&#about-responsive {
		background-image: image_url('banners/about-responsive.jpg');
		background-position: top center;
	}

	&#people {
		background-image: image_url('banners/people.jpg');
	}

	&#work {
		background-image: image_url('banners/work.jpg');
	}

	&#careers {
		background-image: image_url('banners/careers.jpg');
	}

	&#not-found {
		background-image: image_url('banners/404.jpg');
	}

	& .extra {
		@include adelle-sans(700);

		letter-spacing: 0.05rem;
		width: 100%;
		text-align: center;
		color: #fff;
		font-style: italic;
		font-size: 1.15rem;
		position: absolute;

		@include breakpoint(small) {
			bottom: 40px;
		}
		@include breakpoint(medium) {
			bottom: 70px;
		}
		@include breakpoint(large) {
			bottom: 25px;
		}
	}

	& .inner {
		max-width: $global-width;
		margin: 0px auto;
		display: block;
		position: relative;

		@include breakpoint(medium) {
			height: 450px;
		}

		@include breakpoint(large) {
			height: 475px;
		}
	}

	& .vertical-center {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;

		@include breakpoint(small) {
			height: map-get($text-height, small);
		}
		@include breakpoint(medium) {
			height: map-get($text-height, medium);
		}
		@include breakpoint(large) {
			height: map-get($text-height, large);
		}

		&.with-band {
			margin-top: -6px;
		}
	}

	& .banner-text {
		@include absolute;

		color: #FFF;
		text-align: center;
		z-index: 1;
		width: 100%;

		@include breakpoint(medium) {
			padding: 0.5rem 1rem;
		}

		&.bg {
			background: rgba(#000, 0.5);
		}
	}

	& .text-bg {
		@include absolute;

		background: rgba($blue, 0.6);
		mix-blend-mode: multiply;

		&.custom {
			mix-blend-mode: initial;
		}
	}

	/* Banner Text Styles */
	& .pre-text {
		@include adelle-sans(700);
		@include responsive-font(2vw, 20px, 40px, 20px);

		font-style: italic;
		letter-spacing: 0.05rem;
		display: block;

		@include breakpoint(small) {
			line-height: 1.75rem;
		}
		@include breakpoint(medium) {
			line-height: 1.75rem;
		}
		@include breakpoint(large) {
			line-height: 2.5rem;
		}
	}

	& .title {
		@include lato(700);
		@include responsive-font(6vw, 30px, 4.5rem, 4rem);

		text-transform: uppercase;
		display: block;

		@include breakpoint(small) {
			line-height: 2rem;
		}
		@include breakpoint(medium) {
			line-height: 3.75rem;
		}
		@include breakpoint(large) {
			line-height: 5.25rem;
		}
	}

	& .thin {
		@include lato(300);
		@include responsive-font(6vw, 23px, 3.5rem, 4rem);

		text-transform: uppercase;
		display: block;

		@include breakpoint(small) {
			line-height: 2rem;
		}
		@include breakpoint(medium) {
			line-height: 3.25rem;
		}
		@include breakpoint(large) {
			line-height: 3.25rem;
		}

		/*
		 * For banners without the third line of text, we have to jog the text down
		 * slightly to get it to align vertically on larger screens.
		 */
		&.two-line {
			@include breakpoint(small) {
				margin-top: 0.75rem;
			}
			@include breakpoint(medium) {
				margin-top: 1rem;
			}
			@include breakpoint(large) {
				margin-top: 2rem;
			}
		}
	}

	/* Knock Knock Styles */
	& .knock-knock {
		@include adelle-sans(700);

		font-size: 0.85rem;
		background: #fff;
		position: absolute;
		top: 0;
		right: 0;
		width: 75px;
		height: 40px;
		color: $blue;
		text-align: center;
		font-style: italic;
		border-radius: 0 0 10px 10px;
		padding: 5px 0;
		line-height: 100%;

		@include breakpoint(medium) {
			width: 85px;
			height: 50px;
			line-height: 140%;
		}

		&:after {
			content: '';
			background-image: image-url('home/knock-knock-beak.svg');
			background-repeat: no-repeat;
			background-position: 35% 50%;
			position: absolute;
			bottom: -20px;
			left: 0;
			width: 100%;
			height: 25px;
		}
	}

	/* Colored band for client pages */
	& .band {
		position: absolute;
		bottom: 0px;
		width: 100%;
		height: 12px;
	}
}

/* This is a fix to make sure that anything that is put through big text will fit on the same line */
.big .title {
	@include breakpoint(small only) {
		font-size: 4vw;
	}
}

/* Animation */
.slick.animate {
	& .vertical-center {
		@include breakpoint(small) {
			top: 50%;
		}
		@include breakpoint(large) {
			/* Start for animation */
			transition: top 0.75s;
			transition-timing-function: cubic-bezier(.77,0,.18,1);
			top: -500px;
		}
	}

	& .visible .vertical-center {
		top: 50%;
	}
}

/*
 * This is banner styles that don't use a multipling background and can word
 * wrap.
 */
.banner.wrap {
	display: flex;
	flex-direction: column;
	justify-content: center;

	& .vertical-center {
		position: relative;
		top: initial;
		height: auto;
	}

	& .banner-text {
		height: auto;
		position: relative;
		top: initial;
	}
}
