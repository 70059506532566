@import "core/core";
@import "foundation";

/* Core SCSS Files */
@import "core/footer";
@import "core/forms";
@import "core/header";
@import "core/layout";
@import "core/links";

/* Core Modules */
@import "modules/banner";
@import "modules/positioning-statement";

/* Woo! Placeholders!  This is *literally* the ugliest color I can think of. */
.placeholder {
	color: #FF69B4 !important;
}

/*
 * Remaining Foundation Imports
 *
 * We import this way to allow us to pick and choose which foundation elements
 * to include to keep the css lean.  If config or additional classes are needed,
 * they should be put in the "Core" directory and imported in the above section.
 */
@include foundation-callout;
@include foundation-flex-grid;
@include foundation-flex-classes;
@include foundation-menu;
@include foundation-visibility-classes;

/* Global fixes */
* {
	box-sizing: border-box;
}

body {
	margin: 0;
	position: relative;
	font-size: 1.05rem;
	line-height: 175%;
}

img {
	border: none;
}

.row:not(.expanded) {
	@include breakpoint (small only) {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}
