$hover-time: 0.2s;

%link {
	@include adelle-sans(600);

	text-decoration: none;
	text-transform: lowercase;
	font-style: italic;
	padding-bottom: 3px;
	font-size: 0.9rem;
	letter-spacing: 0.05rem;
	transition: $hover-time linear;

	&:hover {
		transition: $hover-time linear;
	}
}

a {
	&.blue {
		@extend %link;

		color: $blue;
	}

	&.red {
		@extend %link;

		color: $red;
	}

	&.white {
		@extend %link;

		color: #fff;
	}

	&.blue-border {
		border-bottom: 3px solid $blue;

		&:hover {
			border-bottom-color: $green;
		}
	}

	&.green-border {
		border-bottom: 3px solid $green;

		&:hover {
			border-bottom-color: $blue;
		}
	}

	&.white-border {
		border-bottom: 3px solid #fff;

		&:hover {
			border-bottom-color: $red;
		}
	}
}

.cta {
	& a {
		margin: 0 auto;
	}
}

p a:not(.work-icon):not(.ability):not(.custom) {
	@extend %link;

	text-transform: initial;
	color: $blue;

	&:visited {
		color: $blue;
	}

	&:hover {
		color: $green;
	}

	&:active {
		color: $green;
	}
}
